import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import process from "../assets/images/process.gif";
import logo from "../assets/images/NodePressBlog.png";

import axios from "../api/axios";
const LOGIN_URL = `${process.env.REACT_APP_SERVER_URL}/api/auth/signin`;

const Login = () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("username");

  function change_text() {
    document.getElementById(
      "button"
    ).innerHTML = `<img src=${process}! /> You are being authenticated...`;
  }
  function reset_text() {
    document.getElementById("button").innerHTML = "Sign In";
  }
  const { setAuth } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  // const from = location.state?.from?.pathname || "/";
  const from = location.state?.from?.pathname || location?.pathname || "/";

  const userRef = useRef();
  const errRef = useRef();
  const [username, setUser] = useState("");
  const [password, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    change_text();
    // changeStyle();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const userName = response?.data?.username;
      const accessToken = response?.data?.accessToken;
      const refreshToken = response?.data?.refreshToken;

      const roles = response?.data?.roles;
      setAuth({ username, password, roles, accessToken });
      setUser("");
      setPwd("");
      localStorage["username"] = userName;
      localStorage["accessToken"] = accessToken;
      localStorage["refreshToken"] = refreshToken;
      // navigate(from, { replace: true });
      // window.location.reload(true);

      if (from !== "/login") {
        navigate(from, { replace: true });
      } else {
        navigate("/", { replace: true });
      }
      window.location.reload(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
        reset_text();
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
        reset_text();
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");

        reset_text();
      } else {
        setErrMsg("Login Failed");

        reset_text();
      }
      errRef.current.focus();
    }
  };

  return (
    <section style={{ color: "white" }}>
      <div
        style={{ textAlign: "center", paddingTop: "5px" }}
        className="col-md-12"
      >
        <img src={logo} className="nodepressblog-logo" alt="NodePress Blog" />
        <br />

        <p
          ref={errRef}
          className={errMsg ? "errmsg" : "offscreen"}
          aria-live="assertive"
        >
          {errMsg}
        </p>
        <br />

        <p>Please enter your NodePress Blog information</p>
      </div>

      <form className="login" onSubmit={handleSubmit}>
        <label style={{ paddingTop: "5px" }} htmlFor="username">
          Username:
        </label>
        <input
          type="text"
          id="username"
          ref={userRef}
          autoComplete="off"
          onChange={(e) => setUser(e.target.value)}
          value={username}
          required
        />
        <label style={{ paddingTop: "5px" }} htmlFor="password">
          Password:
        </label>
        <input
          type="password"
          id="password"
          onChange={(e) => setPwd(e.target.value)}
          value={password}
          required
        />
        <br />
        <button className="btn btn-primary submit" id="button">
          Sign In
        </button>
      </form>
      <div className="login-panel">
        <p>
          Need an Account? &nbsp;
          <span className="line">
            <Link to="/register">Sign Up</Link>
          </span>
        </p>
      </div>
    </section>
  );
};

export default Login;
