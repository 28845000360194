import React, { useState, useEffect } from "react";
import axios from "axios";

const AddNewTags = () => {
  const [tagsName, setTagName] = useState("");
  const [tagsSlug, setTagSlug] = useState("");
  const [tagsDescription, setTagDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const fetchData = async () => {
    const result = await axios.get(
      process.env.REACT_APP_SERVER_URL + "/api/v1/tags"
    );
    setTags(result.data.tags);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (update) {
      const res = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/v1/tags/${updateId}`,
        {
          name: tagsName,
          slug: tagsSlug,
          description: tagsDescription,
        }
      );
      const newTags = tags.map((tags) =>
        tags.id === updateId ? res.data : tags
      );
      setTags(newTags);
      setUpdate(false);
      setUpdateId("");
      setTagName("");
      setTagSlug("");
      setTagDescription("");
    } else {
      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/v1/tags/",
        {
          name: tagsName,
          slug: tagsSlug,
          description: tagsDescription,
        }
      );

      console.log(res.data, "Response Data");
      setTags([...tags, res.data.tag]);
      setTagName("");
      setTagSlug("");
      setTagDescription("");
    }
  };

  const handleUpdate = (id) => {
    const tags = tags.find((tags) => tags.id === id);
    setUpdate(true);
    setUpdateId(id);
    setTagName(tags.name);
    setTagSlug(tags.slug);
    setTagDescription(tags.description);
  };

  const handleDelete = async (id) => {
    await axios.delete(process.env.REACT_APP_SERVER_URL + `/api/v1/tags/${id}`);
    const newTags = tags.filter((tags) => tags.id !== id);
    setTags(newTags);
  };

  const handleEdit = async (id) => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + `/api/v1/tags/${id}`
    );
    setTagName(res.data.name);
    setTagSlug(res.data.slug);
    setTagDescription(res.data.description);
    setUpdate(true);
    setUpdateId(id);
  };
  return (
    <div className="container">
      <h2 className="text-center my-5">Add New Tag</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="tagsName">Name</label>
          <input
            type="text"
            className="form-control"
            id="tagsName"
            value={tagsName}
            onChange={(e) => setTagName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="tagsSlug">Slug</label>
          <input
            type="text"
            className="form-control"
            id="tagsSlug"
            value={tagsSlug}
            onChange={(e) => setTagSlug(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="tagsDescription">Description</label>
          <textarea
            className="form-control"
            id="tagsDescription"
            value={tagsDescription}
            onChange={(e) => setTagDescription(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {update ? "Update Tag" : "Add New Tag"}
        </button>
      </form>
      <div className="my-5">
        <h3>Tags</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Slug</th>
              <th>Description</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tags.map((tags) => (
              <tr key={tags.id}>
                <td>{tags.name}</td>
                <td>{tags.slug}</td>
                <td>{tags.description}</td>
                <td>
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={() => handleEdit(tags.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDelete(tags.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddNewTags;
