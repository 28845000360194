import React from "react";
import { Quill } from "react-quill";
import axios from "./../../api/axios";
import ImageUploader from "quill-image-uploader";
import ImageResize from "quill-image-resize";
import { FaTrashAlt } from "react-icons/fa";

import htmlEditButton from "quill-html-edit-button";
import "./EditToolbar.css";

const token = localStorage.accessToken;
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
};

// Custom function to handle deleting image
// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

Quill.register("modules/imageResize", ImageResize);

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);
Quill.register({
  "modules/htmlEditButton": htmlEditButton,
});

// Define a custom component for the image
const ImageBlot = Quill.import("formats/image");

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "0";
//     deleteIcon.style.right = "0";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "0%";
//     deleteIcon.style.right = "0%";
//     deleteIcon.style.transform = "translate(50%, -50%)";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "0%";
//     deleteIcon.style.right = "0%";
//     deleteIcon.style.transform = "translate(50%, -50%)";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     deleteIcon.style.display = "none";
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     container.addEventListener("click", (event) => {
//       if (event.target === node) {
//         deleteIcon.style.display = "block";
//       } else {
//         deleteIcon.style.display = "none";
//       }
//     });

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "0%";
//     deleteIcon.style.right = "0%";
//     deleteIcon.style.transform = "translate(50%, -50%)";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     deleteIcon.style.display = "none";
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     node.addEventListener("click", () => {
//       deleteIcon.style.display = "block";
//     });

//     node.onload = () => {
//       const imgWidth = node.offsetWidth;
//       const imgHeight = node.offsetHeight;
//       deleteIcon.style.top = `${imgHeight / 2}px`;
//       deleteIcon.style.right = `${imgWidth / 2}px`;
//     };

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";
//     container.style.display = "inline-block";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "-10px";
//     deleteIcon.style.right = "-10px";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     const adjustDeleteIconPosition = () => {
//       deleteIcon.style.top = `${node.clientHeight / 2 - 10}px`;
//       deleteIcon.style.right = `${node.clientWidth / 2 - 10}px`;
//     };

//     adjustDeleteIconPosition();

//     const observer = new ResizeObserver(adjustDeleteIconPosition);
//     observer.observe(node);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     node.addEventListener("click", () => {
//       deleteIcon.style.display = "block";
//     });

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";
//     container.style.display = "inline-block";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "-10px";
//     deleteIcon.style.right = "-10px";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     const adjustDeleteIconPosition = () => {
//       deleteIcon.style.top = `${node.clientHeight / 2 - 10}px`;
//       deleteIcon.style.right = `${node.clientWidth / 2 - 10}px`;
//     };

//     adjustDeleteIconPosition();

//     const observer = new ResizeObserver(adjustDeleteIconPosition);
//     observer.observe(node);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     node.addEventListener("click", () => {
//       deleteIcon.style.display = "block";
//     });

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const container = document.createElement("div");
//     container.style.position = "relative";
//     container.style.display = "inline-block";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "0";
//     deleteIcon.style.right = "0";
//     deleteIcon.style.margin = "5px";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     const adjustDeleteIconPosition = () => {
//       deleteIcon.style.top = "0";
//       deleteIcon.style.right = "0";
//       deleteIcon.style.margin = "5px";
//       deleteIcon.style.transform = "translate(50%, -50%)";
//       deleteIcon.style.top = `${5 - node.clientHeight / 2}px`;
//       deleteIcon.style.right = `${5 - node.clientWidth / 2}px`;
//     };

//     adjustDeleteIconPosition();

//     const observer = new ResizeObserver(adjustDeleteIconPosition);
//     observer.observe(node);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     node.addEventListener("click", () => {
//       deleteIcon.style.display = "block";
//     });

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);
//     node.setAttribute(
//       "style",
//       `width: ${value.width}px; height: ${value.height}px`
//     );

//     const container = document.createElement("div");
//     container.style.position = "relative";
//     container.style.display = "inline-block";

//     const deleteIcon = document.createElement("span");
//     deleteIcon.innerHTML = "X";
//     deleteIcon.style.position = "absolute";
//     deleteIcon.style.top = "-10px";
//     deleteIcon.style.right = "-10px";
//     deleteIcon.style.cursor = "pointer";
//     deleteIcon.setAttribute("class", "delete-icon");
//     deleteIcon.setAttribute("contentEditable", false);
//     container.appendChild(node);
//     container.appendChild(deleteIcon);

//     const adjustDeleteIconPosition = () => {
//       deleteIcon.style.top = `${node.clientHeight / 2 - 10}px`;
//       deleteIcon.style.right = `${node.clientWidth / 2 - 10}px`;
//     };

//     adjustDeleteIconPosition();

//     const observer = new ResizeObserver(adjustDeleteIconPosition);
//     observer.observe(node);

//     deleteIcon.onclick = () => {
//       const url = node.getAttribute("data-id");
//       // removeImage(url);
//       console.log(url, "Image has been removed");
//       container.remove();
//     };

//     node.addEventListener("click", () => {
//       deleteIcon.style.display = "block";
//     });

//     return container;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     const adjustSize = () => {
//       node.style.width = `${node.clientWidth}px`;
//       node.style.height = `${node.clientHeight}px`;
//     };

//     adjustSize();

//     const observer = new ResizeObserver(adjustSize);
//     observer.observe(node);

//     return node;
//   }
// }

// class CustomImageBlot extends ImageBlot {
//   static create(value) {
//     const node = super.create(value);
//     node.setAttribute("class", "image-resize");
//     node.setAttribute("data-id", value);
//     node.setAttribute("contentEditable", false);

//     // Save the original width and height as data attributes
//     node.setAttribute("data-original-width", node.clientWidth);
//     node.setAttribute("data-original-height", node.clientHeight);

//     const observer = new ResizeObserver((entries) => {
//       // Update the data attributes with the new size information
//       node.setAttribute("data-width", node.clientWidth);
//       node.setAttribute("data-height", node.clientHeight);
//     });

//     observer.observe(node);

//     return node;
//   }

//   static value(node) {
//     // Get the saved size information from the data attributes
//     const originalWidth = node.getAttribute("data-original-width");
//     const originalHeight = node.getAttribute("data-original-height");
//     const width = node.getAttribute("data-width") || originalWidth;
//     const height = node.getAttribute("data-height") || originalHeight;

//     return {
//       url: node.getAttribute("data-id"),
//       width,
//       height,
//     };
//   }
// }

class CustomImageBlot extends ImageBlot {
  static create(value) {
    const node = super.create(value);
    node.setAttribute("class", "image-resize");
    node.setAttribute("data-id", value.src);
    node.setAttribute("contentEditable", false);

    const adjustSize = () => {
      node.style.width = `${node.clientWidth}px`;
      node.style.height = `${node.clientHeight}px`;
    };

    adjustSize();

    const observer = new ResizeObserver(adjustSize);
    observer.observe(node);

    return node;
  }
}

Quill.register(CustomImageBlot, true);

// Quill.register(CustomImageBlot);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,

      codeBlock: () => {
        const quill = this.editor.getQuill();
        const cursorPosition = quill.getSelection().index;
        quill.insertText(cursorPosition, "", { "code-block": true });
        quill.format("code-block", true);
      },
    },
  },

  // // Add delete icon to image
  // imageResize: {
  //   modules: ["Resize", "DisplaySize", "Toolbar"],
  //   handleStyles: function (styles) {
  //     styles.deleteIcon = {
  //       display: "block",
  //       background:
  //         "url('https://img.icons8.com/material-outlined/24/000000/trash.png') no-repeat center",
  //       position: "absolute",
  //       bottom: "0px",
  //       right: "0px",
  //       width: "16px",
  //       height: "16px",
  //       cursor: "pointer",
  //     };
  //     return styles;
  //   },
  //   // Handle delete icon click
  //   handleDelete: function (url) {
  //     console.log(`Image has been deleted`);
  //     deleteIcon(url);
  //   },
  // },

  // imageResize: {
  //   modules: ["Resize", "DisplaySize", "Toolbar"],

  //   handleStyles: function (styles) {
  //     styles.deleteIcon = {
  //       display: "block",
  //       background:
  //         "url('https://img.icons8.com/material-outlined/24/000000/trash.png') no-repeat center",
  //       position: "absolute",
  //       bottom: "0px",
  //       right: "0px",
  //       width: "16px",
  //       height: "16px",
  //       cursor: "pointer",
  //     };
  //     return styles;
  //   },
  //   handleControls: function (controls, image, toolbar) {
  //     // Add delete icon control
  //     const deleteControl = document.createElement("div");
  //     deleteControl.classList.add("ql-delete-icon");
  //     deleteControl.style = controls.deleteIcon;
  //     deleteControl.addEventListener("click", function () {
  //       const url = image.getAttribute("src");
  //       console.log(`Image with url ${url} has been deleted`);
  //       toolbar.handleDelete(url);
  //     });
  //     controls.deleteIcon = deleteControl;

  //     // Add controls to toolbar
  //     toolbar.appendChild(deleteControl);
  //     return controls;
  //   },
  //   // Handle delete icon click
  //   handleDelete: function (url) {
  //     console.log(`Image with url ${url} has been deleted`);
  //   },
  // },

  imageResize: {
    modules: ["Resize", "DisplaySize", "Toolbar"],
  },

  // customImage: {
  //   module: "images",
  //   className: "custom-image",
  //   allowedImageTypes: ["image/jpeg", "image/png"],
  //   uploadCallback: () => {},
  // },
  // For custom image

  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("name", "content");

        axios
          .post(
            `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
            formData,
            config
          )
          .then((response) => response.data.imageurl)
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  htmlEditButton: {},
  // imageResize: {
  //   modules: ["Resize", "DisplaySize", "Toolbar"],
  // },
  // // Custom image delete icon
  // imageDelete: {
  //   deleteIcon,
  // },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
  "image",
];

// Quill Toolbar component
export const QuillToolbar = () => (
  <div
    id="toolbar"
    style={{
      width: "100%",
      marginTop: "20px",
    }}
  >
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <select className="ql-font">
        <option value="arial"> Arial </option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="Inter" defaultValue>
          Inter
        </option>
        <option value="lucida">Lucida</option>
      </select>
      <select className="ql-size">
        <option value="extra-small">Extra Small</option>
        <option value="small">Small</option>
        <option value="medium" defaultValue>
          Medium
        </option>
        <option value="large">Large</option>
      </select>
      <select className="ql-header">
        <option value="1">Heading 1</option>
        <option value="2">Heading 2</option>
        <option value="3">Heading 3</option>
        <option value="4">Heading 4</option>
        <option value="5">Heading 5</option>
        <option value="6">Heading 6</option>
        <option value="" defaultValue>
          Normal
        </option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-list" defaultValue="ordered" />
      <button className="ql-list" defaultValue="bullet" />
      <button className="ql-indent" defaultValue="-1" />
      <button className="ql-indent" defaultValue="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" defaultValue="super" />
      <button className="ql-script" defaultValue="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-video" />
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);
export default QuillToolbar;
