import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";

import { useNavigate } from "react-router-dom";
import { Table, Button } from "react-bootstrap";

import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";
import { RiDashboardFill } from "react-icons/ri";
import { Helmet } from "react-helmet";
import axios from "../../api/axios";
import { debounce } from "lodash";
import "./Comments.css";

const token = localStorage.accessToken;

// Define the function to fetch comments

const fetchComments = async (page, limit, query, sortBy, sortOrder) => {
  // const COMMENTS_PER_PAGE = 10;
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}/api/v1/blog-comments`,
    {
      params: {
        page: page,
        limit: limit,
        q: query,
        sort_by: sortBy,
        sort_order: sortOrder,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

// const BlogComments = () => {
//   const [comments, setComments] = useState([]);
//   const [totalComments, setTotalComments] = useState(0);

//   useEffect(() => {
//     const getCommentsData = async () => {
//       const [commentsData, totalCommentsData] = await Promise.all([
//         fetchComments(),
//         axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/comments/count`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }),
//       ]);
//       setComments(commentsData.results);
//       setTotalComments(totalCommentsData.data.pagination.totalResults);
//     };

//     getCommentsData();
//   }, []);

//   return (
//     <div>
//       <h1>Blog Comments</h1>
//       <p>Total Comments: {totalComments}</p>
//       {comments.map((comment) => (
//         <div key={comment.id}>
//           <h3>{comment.title}</h3>
//           <p>{comment.body}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

export default function Comments() {
  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  // Define the initial state of the comments
  const [comments, setComments] = useState([]);

  // Define the state of the pagination
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  // const [totalComments, setTotalComments] = useState(0);

  // Define the state of the search query
  const [searchQuery, setSearchQuery] = useState("");

  // Define the state of the sorting
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortBy, setSortBy] = useState("createdAt");

  // Define the state of the delete modal
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showReplyModal, setShowReplyModal] = useState(false);

  // Define the state of the reply form
  const [replyBody, setReplyBody] = useState("");

  // Define the navigate function
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchComments = async () => {
  //     // const data = await fetchComments(currentPage);
  //     const data = await fetchComments(1, 10, "", "createdAt", "desc");

  //     setComments(data.results);
  //     setPageCount(data.pagination.totalPages);
  //   };
  //   fetchComments();
  // }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchComments(
        currentPage,
        5, // no of data
        "",
        "createdAt",
        "desc"
      );
      setComments(data);
      setPageCount(data.pagination.totalPages);

      console.log(data, "comments..... 1");
    };

    fetchData();
  }, [sortBy, sortOrder, token, currentPage]);

  // Define the debounced search function
  const debouncedSearch = useCallback(
    debounce((query) => {
      fetchComments(currentPage, query);
    }, 500),
    []
  );

  // Define the function to handle page change
  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  //   fetchComments(page, searchQuery);
  // };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  // Define the function to handle search query change
  const handleSearchQueryChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };

  // Define the function to handle sort change
  const handleSortChange = (sortBy) => {
    if (sortBy === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(sortBy);
      setSortOrder("desc");
    }
    fetchComments(currentPage, searchQuery);
  };

  // Define the function to handle reply form change
  const handleReplyBodyChange = (event) => {
    const value = event.target.value;
    setReplyBody(value);
  };

  // Define the function to handle reply form change
  const handleReplyChange = (e) => {
    setReplyBody(e.target.value);
  };

  // Define the function to delete comment
  const handleDeleteComment = (e) => {
    // delete comment
  };

  // Define the function to reply to comment
  const handleReplyComment = (e) => {
    // reply to comment
  };

  const handleShowReplyModal = (comment) => {
    setSelectedComment(comment);
    setShowReplyModal(true);
  };

  const closeReplyModal = () => {
    setSelectedComment(false);
  };
  // Define the function to handle reply form submit
  const handleReplySubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blog-comments/${selectedComment._id}/replies`,
        { body: replyBody },
        config
      );

      const updatedComments = comments.results.map((comment) => {
        if (comment.id === selectedComment._id) {
          return {
            ...comment,
            replies: [data.comment, ...comment.replies],
          };
        } else {
          return comment;
        }
      });

      setComments(updatedComments);
      setShowReplyModal(false);
      setReplyBody("");
      toast.success("Reply submitted successfully!");
    } catch (err) {
      console.error(err);
      toast.error("Failed to submit reply. Please try again later.");
    }
  };

  return (
    <>
      <Helmet>
        <title>Comments | Blog Admin</title>
      </Helmet>
      <ToastContainer position="bottom-right" />
      <div className="d-flex justify-content-between mb-4">
        <h1 className="mb-0">Comments</h1>
        <Button variant="primary" onClick={() => navigate("/admin")}>
          <RiDashboardFill className="me-2" />
          Go to Dashboard
        </Button>
      </div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search comments"
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              debouncedSearch(e.target.value);
            }}
          />
        </div>
        <Button variant="primary" onClick={() => setShowDeleteModal(true)}>
          Delete Selected Comments
        </Button>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) => {
                  const checked = e.target.checked;
                  const updatedComments = comments?.results?.map((comment) => {
                    return {
                      ...comment,
                      selected: checked,
                    };
                  });
                  setSelectedComment(updatedComments);
                }}
              />
            </th>
            <th>Comment</th>
            <th>User</th>
            <th>Article</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        {comments?.results?.length > 0 ? (
          <tbody>
            {comments?.results.map((comment) => (
              <tr key={comment.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={comment.selected}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      const updatedComments = comments?.results?.map((c) => {
                        if (c.id === comment.id) {
                          return {
                            ...c,
                            selected: checked,
                          };
                        } else {
                          return c;
                        }
                      });
                      setSelectedComment({
                        ...comments,
                        results: updatedComments,
                      });
                    }}
                  />
                </td>
                <td>
                  <div>{comment.body}</div>
                  <div className="mt-2">
                    {/* <Button
                      variant="primary"
                      onClick={() => handleShowReplyModal(comment)}
                    >
                      Reply
                    </Button> */}

                    <div>
                      <Button
                        variant="primary"
                        onClick={() => handleShowReplyModal(comment)}
                      >
                        Reply
                      </Button>
                      {selectedComment && (
                        <Modal
                          show={showReplyModal}
                          onHide={() => setShowReplyModal(false)}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Reply to {selectedComment.title}
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <form onSubmit={handleReplyComment}>
                              <div className="form-group">
                                <label htmlFor="replyBody">Reply</label>
                                <textarea
                                  className="form-control"
                                  id="replyBody"
                                  rows="3"
                                  value={replyBody}
                                  onChange={handleReplyBodyChange}
                                ></textarea>
                              </div>
                            </form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={() => setShowReplyModal(false)}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleReplyComment}
                            >
                              Reply
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  <div>{comment.author}</div>
                </td>
                <td>
                  <div>{comment.blog_id}</div>
                </td>
                <td>
                  <div>{new Date(comment?.updatedAt).toLocaleString()}</div>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={5}>
                <p>Nothing...</p>
              </td>
            </tr>
          </tbody>
        )}
      </Table>

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />
      {/* Define the delete modal */}

      {/* <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        contentLabel="Delete Comment"
      >
        <h2>Delete Comment</h2>
        <p>Are you sure you want to delete this comment?</p>
        <div className="d-flex justify-content-end">
          <button
            className="btn btn-light mr-2"
            type="button"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-danger"
            type="button"
            onClick={handleDeleteComment}
          >
            Delete
          </button>
        </div>
      </Modal>







      <Modal
        isOpen={showReplyModal}
        onRequestClose={() => setShowReplyModal(false)}
        contentLabel="Reply to Comment"
      >
        <h3>Reply to Comment</h3>
        <form>
          <div className="form-group">
            <label htmlFor="replyBody">Comment Body</label>
            <textarea
              className="form-control"
              id="replyBody"
              rows="5"
              value={replyBody}
              onChange={handleReplyBodyChange}
            />
          </div>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light mr-2"
              type="button"
              onClick={() => setShowReplyModal(false)}
            >
              Cancel
            </button>
            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </div>
        </form>
      </Modal> */}

      <Modal
        // id="ticket-container"
        isOpen={showReplyModal}
        onRequestClose={closeReplyModal}
        // style={customStyles}
        contentLabel="Message"
      >
        <h3>Reply Message</h3>

        <button onClick={closeReplyModal} id="x">
          X
        </button>

        <form>
          <>
            <div className="form-group">
              <label htmlFor="replyBody">Comment Body</label>
              <textarea
                className="form-control"
                id="replyBody"
                rows="5"
                value={replyBody}
                onChange={handleReplyBodyChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-light mr-2"
                type="button"
                onClick={() => setShowReplyModal(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </div>
          </>
        </form>
      </Modal>
      <ToastContainer />
    </>
  );
}
