import React, { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { FaGlobe, FaList, FaPencilAlt, FaImage } from "react-icons/fa";
import { FcAddImage } from "react-icons/fc";
import axios from "../../api/axios";
import ReactQuill from "react-quill";
import "./AddBlog.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import EditorToolbar, {
  modules,
  formats,
} from "../../modules/toolbar/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "../../modules/toolbar/EditToolbar.css";
const BLOG_IMAGE_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`;
const BLOG_POST_URL = `${process.env.REACT_APP_SERVER_URL}/api/v1/blog/`;

export const EditPage = () => {
  const { id } = useParams();
  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [state, setState] = React.useState({ value: null });
  const [postTitle, setTitle] = useState("");
  const [inputTagsValue, setInputTagsValue] = useState("");
  const [featureImage, setFeatureImage] = useState("");
  const [postExcerpt, setExcerpt] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [postStatus, setPostStatus] = useState();

  useEffect(() => {
    const getPosts = axios
      .get(`/api/v1/blogid/${id}`, config)
      .then((response) => {
        setTitle(response.data.post?.post_title);
        setFeatureImage(response.data?.post.cover_image);
        setExcerpt(response.data.post?.post_excerpt);
        setState({ value: response.data?.post.post_content });
        setPostStatus(response.data?.post?.post_status);
        setSelectedDate(response.data?.createdAt);
      });

    //     if (!getPosts) {
    //     }
    //     // Fetch categories from API and set them in state
    //     // async function fetchCategories() {
    //     //   const token = localStorage.accessToken;
    //     //   const config = {
    //     //     headers: {
    //     //       Authorization: `Bearer ${token}`,
    //     //     },
    //     //   };
    //     //   const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1/category/`;
    //     //   const response = await axios.get(apiUrl, config);
    //     //   setCategories(response.data?.categories);
    //     // }
    //     // fetchCategories();
    //
  }, []);

  const handleTitleUpdate = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const handleExcerptUpdate = (event) => {
    event.preventDefault();
    setExcerpt(event.target.value);
  };

  const removeImage = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
        { name: "delete", type: "old", deleteImage: featureImage, id: id },
        config
      )
      .then((response) => {
        toast.success("Cover image has been removed sucessfully 🤯");
        // const url = response.data.cover_image;
        setFeatureImage(false);
        // Handle the successful response from the server
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Error in uploading image.🤯");
      });
  };
  const handleFeatureImageUpload = async (event) => {
    event.preventDefault();
    // toast.pending("Image is being uploaded 🤯");

    const image = event.target.files[0];
    const ImageData = new FormData();
    ImageData.append("image", image);
    ImageData.append("name", "cover");
    ImageData.append("type", "old");
    ImageData.append("id", id);

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
        ImageData,
        config
      )
      .then((response) => {
        toast.success("Cover image has been replaced sucessfully 🤯");
        const url = response.data.cover_image;
        setFeatureImage(url);
        // Handle the successful response from the server
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Error in uploading image.🤯");
      });
  };

  function formatDate(date) {
    return date.toLocaleString();
  }

  function handleDateChange(date, time) {
    if (!time || typeof time.getHours !== "function") {
      return;
    }
    const selectedDateWithTime = date;
    selectedDateWithTime.setHours(time.getHours());
    selectedDateWithTime.setMinutes(time.getMinutes());
    selectedDateWithTime.setSeconds(time.getSeconds());
    setSelectedDate(selectedDateWithTime);
  }

  function timeCaption(time) {
    if (!time) {
      return "";
    }
    return `${time.toString()}`;
  }

  const placeholder = formatDate(new Date());

  const handleEditorChange = (value) => {
    setState({ value });
  };

  async function postBlog(event) {
    event.preventDefault();
    const mode = event.target.id === "publish" ? "publish" : "draft";

    if (!postTitle || !state.value || !postExcerpt || !featureImage) {
      toast.error("Please make sure all required fields are filled 🤯");
      return;
    } else {
      const ObjData = {
        post_title: postTitle,
        post_content: state.value,
        post_excerpt: postExcerpt,
        cover_image: featureImage,
        type: "page",
        date: selectedDate,
        status: mode,
      };

      await axios
        .put(`${BLOG_POST_URL}${id}`, ObjData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // handle successful response
          window.location.reload(true);
        })
        .catch((error) => {
          // handle error
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>Edit Page - {postTitle}</title>
      </Helmet>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default panel-asbestos">
              <div className="panel-heading">
                <FaPencilAlt /> &nbsp; EDIT PAGE
              </div>

              <div className="panel-body">
                <div className="col-sm-9">
                  {/*  Starts from Here */}
                  <div className="text-editor">
                    <p>Title:</p>
                    <input
                      style={{ width: "100%", padding: "1%" }}
                      type="text"
                      onChange={handleTitleUpdate}
                      value={postTitle}
                      placeholder="Add Title"
                      // size="30"
                    />
                    <br />
                    <p>Subtitle:</p>
                    <input
                      style={{ width: "100%", padding: "1%" }}
                      type="text"
                      onChange={handleExcerptUpdate}
                      value={postExcerpt}
                      placeholder="Excerpt"
                    />
                    <br />
                    <div
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <EditorToolbar />
                    </div>{" "}
                    <ReactQuill
                      theme="snow" // snow, bubble
                      value={state.value}
                      onChange={handleEditorChange}
                      placeholder={"Write something awesome..."}
                      modules={modules}
                      formats={formats}
                      style={{
                        width: "100%",
                      }}
                    />
                    <br />{" "}
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="panel panel-default panel-blue">
                    <div className="panel-heading">
                      <FaGlobe /> &nbsp; Publish
                      <div className="pull-right">
                        {postStatus && postStatus === "publish" ? (
                          <p className="btn btn-renew bg-color-blue btn-xs">
                            Published
                          </p>
                        ) : (
                          <p className="btn btn-renew bg-color-blue btn-xs">
                            Draft
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="panel-body">
                      {selectedDate ? (
                        <p>
                          The article will be posted on date{" "}
                          {selectedDate.toLocaleString()}
                        </p>
                      ) : (
                        <p>Select date below</p>
                      )}{" "}
                      <div wrapperclassname="datepicker">
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => handleDateChange(date, date)}
                          showTimeSelect
                          timeCaption={timeCaption(selectedDate)}
                          placeholderText={
                            selectedDate
                              ? formatDate(selectedDate)
                              : placeholder
                          }
                        />
                      </div>
                      <br />
                      <button
                        onClick={postBlog}
                        id="publish"
                        className="btn btn-success btn-sm"
                        style={{ display: "inline-block", float: "left" }}
                      >
                        Publish
                      </button>
                      <button
                        onClick={postBlog}
                        id="draft"
                        className="btn btn-sm"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        Save as Draft
                      </button>
                    </div>
                    <div className="panel-footer"></div>
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="panel panel-default panel-blue">
                    <div className="panel-heading">
                      <FaImage /> &nbsp; Featured Image
                      <div className="pull-right">
                        {featureImage && featureImage !== "" ? (
                          <button
                            className="btn btn-renew bg-color-blue btn-xs"
                            onClick={removeImage}
                          >
                            Remove
                          </button>
                        ) : (
                          <p></p>
                        )}
                      </div>
                    </div>

                    <div className="panel-body">
                      {featureImage && featureImage !== "" ? (
                        <img
                          src={featureImage}
                          alt="Featured Img"
                          className="img-responsive"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <input
                          style={{ width: "100%" }}
                          type="file"
                          value={featureImage}
                          onChange={handleFeatureImageUpload}
                          className="btn"
                        />
                      )}
                    </div>
                    <div className="panel-footer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditPage;
