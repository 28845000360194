import React, { useState, useEffect } from "react";
import axios from "../../api/axios";
import { FaGlobe, FaList, FaPencilAlt, FaImage } from "react-icons/fa";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Table, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import "react-toastify/dist/ReactToastify.css";

const formatDate = (date) => {
  const d = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return d.toLocaleString("en-US", options);
};

function PageList(props) {
  const navigate = useNavigate();

  const [posts, setPosts] = React.useState([]);
  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/v1/mypages`;

  React.useEffect(() => {
    axios.get(apiUrl, config).then((response) => {
      setPosts(response.data.pageProps);
    });
  }, []);

  console.log(posts, "posts");

  function deletePost(postId) {
    if (window.confirm("Are you sure you want to delete this post?")) {
      axios.delete(`/api/posts/${postId}`).then(() => {
        setPosts(posts.filter((post) => post.id !== postId));
      });
    }
  }

  function openEditForm(postId) {
    let to = `/pages/edit/${postId}`;
    navigate(to, { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>Blog List</title>
      </Helmet>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default panel-asbestos">
              <div className="panel-heading">
                <FaPencilAlt /> &nbsp; LIST OF PAGES
              </div>

              <div className="panel-body">
                <div className="col-sm-9">
                  {/*  Starts from Here */}

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Views</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {posts.map((post) => (
                        <tr key={post.id} id={post.id}>
                          <td>
                            <a href={`/pages/edit/${post.id}`}>
                              {post?.post_title}
                            </a>
                          </td>
                          <td>
                            {post?.username} {post?.full_name}
                          </td>
                          <td>{post?.views}</td>
                          <td>
                            {" "}
                            Published: {formatDate(post.createdAt)} <br />
                            Updated: {formatDate(post.updatedAt)}{" "}
                          </td>

                          <td
                            style={{
                              color:
                                post?.post_status === "draft"
                                  ? "yellow"
                                  : "green",
                            }}
                          >
                            {post?.post_status}
                          </td>
                          <td>
                            <Button
                              variant="primary"
                              onClick={() => openEditForm(post.id)}
                            >
                              Edit
                            </Button>{" "}
                            <Button
                              variant="danger"
                              onClick={() => deletePost(post.id)}
                            >
                              Delete
                            </Button>{" "}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default PageList;
