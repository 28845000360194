import { useLocation, Navigate, Outlet } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import axios from "axios";

const TokenbaseUrl = `${process.env.REACT_APP_SERVER_URL}/api/auth/refreshtoken`;
const refreshToken = localStorage.getItem("refreshToken");
const accessToken = localStorage.getItem("accessToken");
let LoginURL = "/login"; //add your homeURL here
const checkUser = () => {
  if (
    refreshToken &&
    accessToken !== null &&
    refreshToken &&
    accessToken !== "undefined"
  ) {
    // request interceptor to add the auth token header to requests
    axios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers["authorization"] = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        return new Promise((resolve) => {
          const originalRequest = error.config;

          const refreshToken = localStorage.getItem("refreshToken");

          if (
            error.response &&
            error.response.status === 401 &&
            error.config &&
            !error.config.__isRetryRequest &&
            refreshToken
          ) {
            originalRequest._retry = true;
            const response = fetch(TokenbaseUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                refreshToken: refreshToken,
              }),
            })
              .then((res) => res.json())
              .then((res) => {
                localStorage.setItem("accessToken", res.accessToken);
                localStorage.setItem("refreshToken", res.refreshToken);

                return axios(originalRequest);
              });
            resolve(response);
          }

          // if (error.response.status === 403) {
          //   return <Navigate to={LoginURL} />;
          // }

          if (error.response.status === 403) {
            // redirect to 403 page
            window.location = "/login";
          }
          return Promise.reject(error);
        });
      }
    );

    return true;
  } else {
    return false;
  }
};
const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();
  const user = { loggedIn: checkUser() };

  return (user && user.loggedIn) ||
    auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.username ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : auth ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;

// import { useLocation, Navigate, Outlet } from "react-router-dom";
// import useAuth from "../hooks/useAuth";

// const RequireAuth = ({ allowedRoles }) => {
//   const { auth } = useAuth();
//   const location = useLocation();
//   return auth?.roles?.find((role) => allowedRoles?.includes(role)) ? (
//     <Outlet />
//   ) : auth?.username ? (
//     <Navigate to="/unauthorized" state={{ from: location }} replace />
//   ) : auth ? (
//     <Navigate to="/login" state={{ from: location }} replace />
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };

// export default RequireAuth;
