import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaMoneyBill } from "react-icons/fa";

export default function TotalData() {
  const [UserInfo, fetchUserInfo] = useState(null);

  const getUserData = async () => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + `/dashboard-info`)
      .then((res) => {
        fetchUserInfo(res.data.count);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  // const TotalData = {
  //   totalPosts: 125,
  //   totalPages: 20,
  //   totalComments: 40,
  // };

  if (UserInfo) {
    return (
      <>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Posts</h5>
              <p className="card-text">{UserInfo?.posts}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Pages</h5>
              <p className="card-text">{UserInfo?.pages}</p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Total Comments</h5>
              <p className="card-text">{UserInfo?.comments}</p>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <>
          <div className="col-sm-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Posts</h5>
                <p className="card-text">Loading.....</p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Pages</h5>
                <p className="card-text">Loading.....</p>
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Total Comments</h5>
                <p className="card-text">Loading.....</p>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}
