import { useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import AuthContext from "../../context/AuthProvider";
import CAUserProfile from "../../components/lib/CAUserProfile";
import { Table, Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { FaSearch } from "react-icons/fa";
import { RiDashboardFill } from "react-icons/ri";
import { Helmet } from "react-helmet";
import axios from "../../api/axios";

export default function UserList() {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [users, setUsers] = React.useState([]);

  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const apiUrl = `${process.env.REACT_APP_SERVER_URL}/api/users`;

  React.useEffect(() => {
    axios.get(apiUrl, config).then((response) => {
      setUsers(response.data.details);

      console.log(users, "users");
    });
  }, []);

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      fetch(`/api/v1/users/delete/${userId}`, { method: "DELETE" })
        .then(() => {
          setUsers(users.filter((user) => user.id !== userId));
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    }
  };

  const logout = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    setAuth({});
    navigate("/linkpage");

    // Remove all the localStorage items
    localStorage.clear();
    // Remove an item with a key "name" the local storage
    // localStorage.removeItem("refreshToken");
  };

  return (
    <>
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>

      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default panel-asbestos">
              <div className="panel-heading">
                <RiDashboardFill /> &nbsp; USER LIST
              </div>

              <div className="panel-body">
                <div className="col-sm-9">
                  {/*  Starts from Here */}

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Posts</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user?.id} id={user.id}>
                          <td>{user?.username}</td>
                          <td>{user?.fullName}</td>
                          <td>{user?.email}</td>
                          <td>{user?.fullName}</td>
                          <td>{user?.isEmailVerified}</td>
                          <td>
                            <button onClick={() => handleDelete(user.id)}>
                              Delete
                            </button>
                            <a href={`/user-edit.php?user_id=${user.id}`}>
                              Edit
                            </a>
                            <button
                              onClick={() =>
                                alert("Password reset email sent!")
                              }
                            >
                              Send Password Reset
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
