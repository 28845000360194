import React, { useState, useEffect } from "react";
import axios from "axios";

const AddNewCategory = () => {
  const [categoryName, setCategoryName] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [categoryParent, setCategoryParent] = useState(0);
  const [categoryDescription, setCategoryDescription] = useState("");
  const [categories, setCategories] = useState([]);
  const [update, setUpdate] = useState(false);
  const [updateId, setUpdateId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(
        process.env.REACT_APP_SERVER_URL + "/api/v1/category"
      );
      setCategories(result.data.categories);
    };
    fetchData();
  }, []);

  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (update) {
      const res = await axios.put(
        process.env.REACT_APP_SERVER_URL + `/api/v1/category/${updateId}`,
        {
          name: categoryName,
          slug: categorySlug,
          parent_id: categoryParent,
          description: categoryDescription,
        }
      );
      const newCategories = categories.map((category) =>
        category.id === updateId ? res.data : category
      );
      setCategories(newCategories);
      setUpdate(false);
      setUpdateId("");
      setCategoryName("");
      setCategorySlug("");
      setCategoryParent(0);
      setCategoryDescription("");
    } else {
      const res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "/api/v1/category/",
        {
          name: categoryName,
          slug: categorySlug,
          parent_id: categoryParent,
          description: categoryDescription,
        }
      );
      setCategories([...categories, res.data.category]);
      setCategoryName("");
      setCategorySlug("");
      setCategoryParent(0);
      setCategoryDescription("");
    }
  };

  const handleUpdate = (id) => {
    const category = categories.find((category) => category.id === id);
    setUpdate(true);
    setUpdateId(id);
    setCategoryName(category.name);
    setCategorySlug(category.slug);
    setCategoryParent(category.parent_id);
    setCategoryDescription(category.description);
  };

  const handleDelete = async (id) => {
    await axios.delete(
      process.env.REACT_APP_SERVER_URL + `/api/v1/category/${id}`
    );
    const newCategories = categories.filter((category) => category.id !== id);
    setCategories(newCategories);
  };

  const handleEdit = async (id) => {
    const res = await axios.get(
      process.env.REACT_APP_SERVER_URL + `/api/v1/category/${id}`
    );
    setCategoryName(res.data.name);
    setCategorySlug(res.data.slug);
    setCategoryParent(res.data.parent_id || 0);
    setCategoryDescription(res.data.description);
    setUpdate(true);
    setUpdateId(id);
  };
  return (
    <div className="container">
      <h2 className="text-center my-5">Add New Category</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="categoryName">Name</label>
          <input
            type="text"
            className="form-control"
            id="categoryName"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="categorySlug">Slug</label>
          <input
            type="text"
            className="form-control"
            id="categorySlug"
            value={categorySlug}
            onChange={(e) => setCategorySlug(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="categoryParent">Parent Category</label>
          <select
            className="form-control"
            id="categoryParent"
            value={categoryParent}
            onChange={(e) => setCategoryParent(e.target.value)}
          >
            <option value="">None</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="categoryDescription">Description</label>
          <textarea
            className="form-control"
            id="categoryDescription"
            value={categoryDescription}
            onChange={(e) => setCategoryDescription(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {update ? "Update Category" : "Add New Category"}
        </button>
      </form>
      <div className="my-5">
        <h3>Categories</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Slug</th>
              <th>Parent</th>
              <th>Description</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {categories.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>{category.slug}</td>
                <td>
                  {category.parent_id
                    ? categories.find((c) => c.id === category.parent_id).name
                    : "None"}
                </td>
                <td>{category.description}</td>
                <td>
                  <button
                    className="btn btn-sm btn-warning"
                    onClick={() => handleEdit(category.id)}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => handleDelete(category.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddNewCategory;
