import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaMoneyBill } from "react-icons/fa";
import { FcGlobe } from "react-icons/fc";

const formatDate = (date) => {
  const d = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return d.toLocaleString("en-US", options);
};
export default function TopPosts() {
  const [getRecentPosts, setRecentPosts] = useState([]);

  const getRecentlyPublishedPosts = async () => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/v1/posts/recent/yearly`)
      .then((res) => {
        setRecentPosts(res.data.posts);
      });
  };
  useEffect(() => {
    getRecentlyPublishedPosts();
  }, []);

  const RecentlyPublished = getRecentPosts;

  console.log(RecentlyPublished, "RecentlyPublished");
  if (RecentlyPublished) {
    return (
      <>
        <div className="panel panel-default panel-black">
          <div className="panel-heading">
            <FcGlobe />
            &nbsp; Recently Published
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Recently Published">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {RecentlyPublished.map((post) => (
                        <tr key={post.id} id={post.id}>
                          <td>
                            <a href={`/blog/edit/${post.id}`}>
                              {post?.post_title}
                            </a>
                          </td>
                          <td> {formatDate(post.updatedAt)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">View All Posts</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="panel panel-default">
          <div className="panel-heading">
            <FaMoneyBill />
            &nbsp; Top Posts
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Coming Soon">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>Loading.....</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer"></div>
        </div>
      </>
    );
  }
}
