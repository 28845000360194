import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaMoneyBill } from "react-icons/fa";
import { FcSearch } from "react-icons/fc";
export default function SEOKeywords() {
  const [UserInfo, fetchUserInfo] = useState(null);

  const getUserData = async () => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + `/profile-information`)
      .then((res) => {
        fetchUserInfo();
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  const seoKeywords = [{ keyword: "Sorry, nothing to report." }];

  if (seoKeywords) {
    return (
      <>
        <div className="panel panel-default panel-blue">
          <div className="panel-heading">
            <FcSearch />
            &nbsp; Top Searches
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Top Searches">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Keyword</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>
                      {seoKeywords.map((keyword) => (
                        <tr key={keyword.id} id={keyword.id}>
                          <td>{keyword.name}</td>
                          <td>{keyword.views}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {seoKeywords.length === 0 && (
                    <p className="text-center">Sorry, nothing to report.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">View All Posts</div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="panel panel-default">
          <div className="panel-heading">
            <FaMoneyBill />
            &nbsp; Top Posts
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Coming Soon">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>Loading.....</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer"></div>
        </div>
      </>
    );
  }
}
