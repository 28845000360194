import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Footer from "./Footer";

const Layout = () => {
  return (
    <div>
      <Sidebar />
      <main className="App">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};

//       <Sidebar /> is temporary removed from Layout
export default Layout;
