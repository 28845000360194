import React, { useState, useEffect } from "react";
import { FaGlobe, FaList, FaPencilAlt, FaImage } from "react-icons/fa";
import axios from "../../api/axios";
import ReactQuill from "react-quill";
import "./AddBlog.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import EditorToolbar, {
  modules,
  formats,
} from "../../modules/toolbar/EditorToolbar";
import "react-quill/dist/quill.snow.css";
import "../../modules/toolbar/EditToolbar.css";

export const AllPage = () => {
  const token = localStorage.accessToken;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [state, setState] = React.useState({ value: null });
  const [postTitle, setTitle] = useState("");
  const [featureImage, setFeatureImage] = useState("");
  const [postExcerpt, setExcerpt] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);

  const handleTitleUpdate = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
  };

  const handleExcerptUpdate = (event) => {
    event.preventDefault();
    setExcerpt(event.target.value);
  };

  const removeImage = async (event) => {
    event.preventDefault();
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
        { name: "delete", deleteImage: featureImage },
        config
      )
      .then((response) => {
        toast.success("Cover image has been removed sucessfully 🤯");
        setFeatureImage("");
        // Handle the successful response from the server
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Error in uploading image.🤯");
      });
  };

  const handleFeatureImageReplace = async (event) => {
    event.preventDefault();
    // toast.pending("Image is being uploaded 🤯");

    const image = event.target.files[0];
    const ImageData = new FormData();
    ImageData.append("image", image);
    ImageData.append("name", "cover");
    ImageData.append("type", "old");

    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
        ImageData,
        config
      )
      .then((response) => {
        toast.success("Image uploaded sucessfully 🤯");

        const url = response.data.cover_image;
        setFeatureImage(url);
        // Handle the successful response from the server
      })
      .catch((error) => {
        toast.error("Error in uploading image.🤯");

        // Handle any errors that occurred during the request
      });
  };
  const handleFeatureImageUpload = async (event) => {
    event.preventDefault();
    // toast.pending("Image is being uploaded 🤯");

    const image = event.target.files[0];
    const ImageData = new FormData();
    ImageData.append("image", image);
    ImageData.append("name", "cover");
    await axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/blogimage`,
        ImageData,
        config
      )
      .then((response) => {
        toast.success("Image uploaded sucessfully 🤯");

        const url = response.data.cover_image;
        setFeatureImage(url);
        // Handle the successful response from the server
      })
      .catch((error) => {
        toast.error("Error in uploading image.🤯");

        // Handle any errors that occurred during the request
      });
  };

  function formatDate(date) {
    return date.toLocaleString();
  }

  function handleDateChange(date, time) {
    if (!time || typeof time.getHours !== "function") {
      return;
    }
    const selectedDateWithTime = date;
    selectedDateWithTime.setHours(time.getHours());
    selectedDateWithTime.setMinutes(time.getMinutes());
    selectedDateWithTime.setSeconds(time.getSeconds());
    setSelectedDate(selectedDateWithTime);
  }

  function timeCaption(time) {
    if (!time) {
      return "";
    }
    return `${time.toString()}`;
  }

  const placeholder = formatDate(new Date());

  const handleEditorChange = async (value) => {
    setState({ value });
  };

  async function postPage(event) {
    event.preventDefault();
    const mode = event.target.id === "publish" ? "publish" : "draft";
    if (!postTitle || !state.value || !postExcerpt || !featureImage) {
      toast.error("Please make sure all required fields are filled 🤯");
      return;
    } else {
      const ObjData = {
        post_title: postTitle,
        post_content: state?.value,
        post_excerpt: postExcerpt,
        cover_image: featureImage,
        categories: 0,
        tags: 0,
        type: "page",
        date: selectedDate,
        status: mode,
      };

      await axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/api/v1/blogcreate/`,
          ObjData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          // handle successful response
          toast.success("Your blog has been sucussfullly posted 🤯");

          window.location.reload(true);
        })
        .catch((error) => {
          // handle error
        });
    }
  }

  return (
    <>
      <Helmet>
        <title>Add Page</title>
      </Helmet>
      <br />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="panel panel-default panel-asbestos">
              <div className="panel-heading">
                <FaPencilAlt /> &nbsp; ADD NEW PAGE
              </div>

              <div className="panel-body">
                <div className="col-sm-9">
                  {/*  Starts from Here */}
                  <div className="text-editor">
                    <input
                      style={{ width: "100%", padding: "1%" }}
                      type="text"
                      onChange={handleTitleUpdate}
                      value={postTitle}
                      placeholder="Add Title"
                      // size="30"
                    />
                    <br />
                    <div
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <EditorToolbar />
                    </div>
                    <ReactQuill
                      theme="snow" // snow, bubble
                      value={state.value}
                      onChange={handleEditorChange}
                      placeholder={"Write something awesome..."}
                      modules={modules}
                      formats={formats}
                      style={{
                        width: "100%",
                      }}
                    />
                    <br />{" "}
                    <input
                      style={{ width: "100%", padding: "1%" }}
                      type="text"
                      onChange={handleExcerptUpdate}
                      value={postExcerpt}
                      placeholder="Excerpt"
                    />
                  </div>
                </div>

                <div
                  className="col-sm-3"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <div>
                    <div className="panel panel-default panel-blue">
                      <div className="panel-heading">
                        <FaGlobe /> &nbsp; Publish
                      </div>

                      <div className="panel-body">
                        {selectedDate ? (
                          <p>
                            The page will be posted on date{" "}
                            {selectedDate.toLocaleString()}
                          </p>
                        ) : (
                          <p>Select date below</p>
                        )}{" "}
                        <div wrapperclassname="datepicker">
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => handleDateChange(date, date)}
                            showTimeSelect
                            timeCaption={timeCaption(selectedDate)}
                            placeholderText={
                              selectedDate
                                ? formatDate(selectedDate)
                                : placeholder
                            }
                          />
                        </div>
                        <br />
                        <button
                          onClick={postPage}
                          id="publish"
                          className="btn btn-success btn-sm"
                          style={{ display: "inline-block", float: "left" }}
                        >
                          Publish
                        </button>
                        <button
                          onClick={postPage}
                          id="draft"
                          className="btn btn-sm"
                          style={{ display: "inline-block", float: "right" }}
                        >
                          Save as Draft
                        </button>
                      </div>
                      <div className="panel-footer"></div>
                    </div>
                  </div>

                  <div>
                    <div className="panel panel-default panel-blue">
                      <div className="panel-heading">
                        <FaImage /> &nbsp; Featured Image
                        <div className="pull-right">
                          {featureImage && featureImage !== "" ? (
                            <button
                              className="btn btn-renew bg-color-blue btn-xs"
                              onClick={removeImage}
                            >
                              Remove
                            </button>
                          ) : (
                            <p></p>
                          )}
                        </div>
                      </div>

                      <div className="panel-body">
                        {featureImage && featureImage !== "" ? (
                          <img
                            onChange={handleFeatureImageReplace}
                            src={featureImage}
                            alt="Featured Img"
                            className="img-responsive"
                            style={{ width: "100%" }}
                          />
                        ) : (
                          <input
                            style={{ width: "100%" }}
                            type="file"
                            value={featureImage}
                            onChange={handleFeatureImageUpload}
                            className="btn"
                          />
                        )}
                      </div>
                      <div className="panel-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AllPage;
