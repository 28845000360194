import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const useAuth = () => {
  if (
    localStorage.getItem("refreshToken") === "undefined" ||
    localStorage.getItem("accessToken") === "undefined"
  ) {
    localStorage.removeItem("refreshToken");
  }
  return useContext(AuthContext);
};
export default useAuth;
