import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { FaMoneyBill } from "react-icons/fa";
import { FcPositiveDynamic } from "react-icons/fc";

export default function TopPosts() {
  const [gettopPosts, setTopPosts] = useState([]);

  const getTopPosts = async () => {
    await axios
      .get(process.env.REACT_APP_SERVER_URL + `/api/v1/top/post`)
      .then((res) => {
        setTopPosts(res.data.posts);
      });
  };
  useEffect(() => {
    getTopPosts();
  }, []);

  const topPosts = gettopPosts;
  if (topPosts) {
    return (
      <>
        <div className="panel panel-default panel-red">
          <div className="panel-heading">
            <FcPositiveDynamic />
            &nbsp; Top Posts
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Top Post">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>
                      {topPosts.map((post) => (
                        <tr key={post.id} id="{post.id}">
                          <td>
                            <a href={`/blog/edit/${post.id}`}>
                              {post?.post_title}
                            </a>
                          </td>
                          <td>{post.views}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <a href="/posts/">View All Posts</a>{" "}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="panel panel-default">
          <div className="panel-heading">
            <FaMoneyBill />
            &nbsp; Top Posts
          </div>
          <div className="panel-body">
            <div className="card_container">
              <div className="card" data-label="Coming Soon">
                <div className="card-container">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Views</th>
                      </tr>
                    </thead>
                    <tbody>Loading.....</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="panel-footer"></div>
        </div>
      </>
    );
  }
}
