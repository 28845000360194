import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import logo from "../assets/images/NodePressBlog.png";
import axios from "axios";
var CryptoJS = require("crypto-js");

const Nav = styled.div`
  background: #15171c;
  /* background: #ffffff; */

  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const showSidebar = () => setSidebar(!sidebar);
  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const username = localStorage.getItem("username");
  const [getPercentageData, setPercentageData] = useState();
  const [getCartDetails, setCartDetails] = useState();
  const [UserBalance, setUserBalance] = useState();
  const [showCartModal, setShowCartModal] = useState(false);

  const toggleCartModal = () => {
    setShowCartModal(!showCartModal);
  };

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_SERVER_URL + `/profile-completion`, { config })
  //     .then((res) => {
  //       var newData = res.data?.percentageCompleted;
  //       setPercentageData(newData);
  //     });

  //   axios
  //     .get(process.env.REACT_APP_SERVER_URL + `/profile-information`)
  //     .then((res) => {
  //       var newData = res.data.data;
  //       const enc = process.env.REACT_APP_ENC_KEY;
  //       var bytes = CryptoJS.AES.decrypt(newData, enc);
  //       var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //       setUserBalance(decryptedData.data[0]?.user_balance);
  //     });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_SERVER_URL + `/api/v1/cart/items`, {
  //       config,
  //     })
  //     .then((res) => {
  //       var cartData = res?.data;
  //       setCartDetails(cartData);
  //     });
  // }, []);

  return refreshToken &&
    accessToken !== null &&
    refreshToken &&
    accessToken !== "undefined" ? (
    <>
      <IconContext.Provider value={{ color: "#ffffff" }}>
        <Nav>
          <NavIcon to="#" style={{ paddingRight: "1%" }}>
            <FaIcons.FaBars
              onClick={showSidebar}
              className={{ paddingRight: "10%" }}
            />
          </NavIcon>
          <a href="/">
            <img
              src={logo}
              className="nodepressblog-logo"
              alt="NodePress Blog"
            />
          </a>

          {/* <p
            className="avl-balance "
            style={{
              cursor: "pointer",
              // background: "#4a9836",
              background: "#4a9836",

              // marginLeft: "200px",
              backgroundColor: "#dff0d8",
              color: "#0f345c",
            }}
          >
            Available Fund {UserBalance}{" "}
            <FaIcons.FaShoppingCart
              size={15}
              color="black"
              onClick={toggleCartModal}
            />
            {getCartDetails?.count > 0 && (
              <p onClick={toggleCartModal} className="cart-count">
                {getCartDetails?.count}
              </p>
            )}
            <span onClick={toggleCartModal} className="cart-count">
              {getCartDetails?.count}
            </span>{" "}
          </p> */}
        </Nav>

        {/* {showCartModal && (
          <div className="cart-modal">
            <AiIcons.AiOutlineCloseCircle onClick={toggleCartModal} />
            <h3>Cart Items</h3>
            {getCartDetails && getCartDetails?.count > 0 ? (
              <ul>
                {getCartDetails?.items?.map((item) => (
                  <li key={item?.domain}>
                    {item?.domain} - {item?.price}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No items in the cart</p>
            )}
          </div>
        )}
        <div className="profile-completion-notification">
          Your profile is {getPercentageData}% complete.
        </div> */}

        <SidebarNav
          className={`nodepressblog-sidebar ${
            sidebar ? "nodepressblog-sidebar-active" : ""
          }`}
          sidebar={sidebar}
        >
          <SidebarWrap>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  ) : (
    <p></p>
  );
};

export default Sidebar;
