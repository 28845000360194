import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as MdIncons from "react-icons/md";
import * as SiIcons from "react-icons/si";
import * as GoIcons from "react-icons/go";
import * as ImIcons from "react-icons/im";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/",
    icon: <AiIcons.AiFillHome />,
  },

  {
    title: "Pages",
    path: "/pages",
    icon: <RiIcons.RiPagesFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "All Pages",
        path: "/pages",
        icon: <RiIcons.RiPagesFill />,
      },
      {
        title: "Add New",
        path: "/pages/add",
        icon: <BsIcons.BsPencilFill />,
      },
    ],
  },

  {
    title: "Posts",
    path: "/posts",
    icon: <RiIcons.RiPagesFill />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "All Posts",
        path: "/posts",
        icon: <RiIcons.RiPagesFill />,
      },
      {
        title: "Add New",
        path: "/posts/add",
        icon: <BsIcons.BsPencilFill />,
      },
    ],
  },

  {
    title: "Categories",
    path: "/category/add",
    icon: <MdIncons.MdCategory />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Category",
        path: "/category/add",
        icon: <MdIncons.MdCategory />,
      },

      {
        title: "Tags",
        path: "/tags/add",
        icon: <BsIcons.BsFillTagsFill />,
      },
    ],
  },

  {
    title: "Comments",
    path: "/comments",
    icon: <FaIcons.FaComments />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "All Comments",
        path: "/comments",
        icon: <FaIcons.FaComments />,
      },
      {
        title: "Spam",
        path: "/comments/spam",
        icon: <BiIcons.BiCommentError />,
      },
    ],
  },

  {
    title: "Contact",
    path: "/contact/",
    icon: <MdIncons.MdContactMail />,
  },

  {
    title: "Users",
    path: "/users",
    icon: <ImIcons.ImUsers />,
  },

  {
    title: "Media",
    path: "/media",
    icon: <IoIcons.IoIosChatboxes />,
  },

  {
    title: "Support",
    path: "/support",
    icon: <IoIcons.IoMdHelpCircle />,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <IoIcons.IoIosSettings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: "Site Settings",
        path: "/settings",
        icon: <IoIcons.IoIosSettings />,
      },
      {
        title: "Permalinks",
        path: "/settings/permalinks",
        icon: <FaIcons.FaLink />,
      },

      {
        title: "Media",
        path: "/settings/media",
        icon: <MdIncons.MdPermMedia />,
      },

      {
        title: "SEO",
        path: "/settings/seo",
        icon: <SiIcons.SiGooglesearchconsole />,
      },

      {
        title: "Others",
        path: "/settings/others",
        icon: <GoIcons.GoPlus />,
      },
    ],
  },
];
