import { useNavigate, Link } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import TopPosts from "./lib/topPosts";
import SEOKeywords from "./lib/seoKeywords";
import RecentlyPublished from "./lib/recentPost";
// import { FaSearch } from "react-icons/fa";
// import { RiDashboardFill } from "react-icons/ri";
import TotalData from "./lib/totalData";

const Home = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = async () => {
    // if used in more components, this should be in context
    // axios to /logout endpoint
    setAuth({});
    navigate("/linkpage");

    // Remove all the localStorage items
    localStorage.clear();
    // Remove an item with a key "name" the local storage
    // localStorage.removeItem("refreshToken");
  };

  return (
    <>
      <div className="panel panel-default panel-blue">
        <div className="container my-5">
          <h1 className="text-center">NodePress Blog Dashboard</h1>
          <div className="row my-5">
            <TotalData />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="panel-body">
            <div className="col-sm-4">
              <TopPosts />
            </div>

            <div className="col-sm-4">
              {/*  Starts from Here */}

              <SEOKeywords />
            </div>
            <div className="col-sm-4">
              {/*  Starts from Here */}
              <RecentlyPublished />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
